<template>
  <div class="create-page" v-if="ready">
    <CForm
      v-on:submit.prevent="createSingleStep"
      novalidate
      class="create-page__form"
    >
      <FormBuilder
        :list="formList"
        @updated="updateSettingsData"
        :page="{ title: texts.createPage.title, info: descriptions }"
      />
    </CForm>
  </div>
  <PreviewSpiner
    v-else
    :texts="['Validating...', 'Preparing...', 'Redirecting...']"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import { kafkaConnectionsTexts } from '@/config/pageTexts/kafkaConnections.json';
import connectionFields from '@/store/modules/settings/connections/fields';
import {  delay } from 'lodash';
import PreviewSpiner from "@/shared/UI/Spiner.vue";
import { connections } from '@/config/descriptions/connections';

export default {
  name: 'CreateKafkaConnection',
  components: {PreviewSpiner},
  data() {
    return {
      ready: true,
      model: 'kafkaConnections',
      descriptions: {
        ...connections.create.kafka,
      },
      texts: {
        ...kafkaConnectionsTexts,
      },
      formList: [],
      settingsData: {},
      requiredFields: [],
      customFields: [],
    };
  },
  computed: {
    ...mapGetters('kafkaConnections', ['message', 'loading']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
  },
  provide() {
    return {
      stepKeys: this.stepKeys,
      model: this.model,
    }
  },
  watch: {
    message(val) {
      if (val === this.texts.createPage.duplicateMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      } else if (val === this.texts.createPage.emptyMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(
      'connections',
      [
        'handleCreateConnection',
        'describeConnectionObject',
      ]
    ),
    async initialize() {
      routerBreadcrumbs(this.$router.currentRoute);

      this.formList = fieldHelpers.prepareCreateFormList(
        connectionFields,
        kafkaConnectionsTexts.createPage,
        connections.create.kafka
      );

      this.formList = this.formList.filter(f => f.key !== 'customFields' && f.key !== 'connectionType');

      const describeConnection = await this.describeConnectionObject('KAFKA');
      this.customFields = describeConnection.customFields;
      this.userConstraints = describeConnection.userConstraints;

      if (this.customFields && this.customFields.length) {
        this.createCustomFields();
      }

      if (this.userConstraints && this.userConstraints.length) {
        this.createUserConstraints();
      }

      let metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');

      if (metaDataIdx !== -1) {
        const brokersIdx = this.formList.findIndex(f => f.key === 'brokers');
        if (brokersIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(brokersIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const groupIdIdx = this.formList.findIndex(f => f.key === 'groupId');
        if (groupIdIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(groupIdIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const topicIdx = this.formList.findIndex(f => f.key === 'topic');
        if (topicIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(topicIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const jksUriIdx = this.formList.findIndex(f => f.key === 'jksUri');
        if (jksUriIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(jksUriIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const tksUriIdx = this.formList.findIndex(f => f.key === 'tksUri');
        if (tksUriIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(tksUriIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const keyDeserializerIdx = this.formList.findIndex(f => f.key === 'key.deserializer');
        if (keyDeserializerIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(keyDeserializerIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const valueDeserializerIdx = this.formList.findIndex(f => f.key === 'value.deserializer');
        if (valueDeserializerIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(valueDeserializerIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const regionIdx = this.formList.findIndex(f => f.key === 'region');
        if (regionIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(regionIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }
      }

      this.formList.forEach(field => {
        if (field.required) {
          this.requiredFields.push(field.key)
        }
      })
    },
    createUserConstraints() {
      this.userConstraints.forEach(c => {
        const userConstraint = {
          'key': c.key,
          'label': c.name,
          'tooltip': this.descriptions[c.key],
          'type': 'SWITCH',
          'model': 'CreateConnectionRequest',
          'value': null,
          'disabled': false,
          'fieldDescriptor': {
            'name': c.name,
            'complexType': null,
            'displayOrder': 2000,
            'allowableValuesKeys': [],
            'constraints': null,
            'formType': null
          },
          'options': null,
          'multiple': false,
          'required': false,
          'showKey': false,
          'isCreate': false,
          'isUpdate': false
        };
        this.formList.unshift(userConstraint);
      });
    },
    createCustomFields() {
      this.customFields.forEach(f => {
        const customField = {
          'key': f.key,
          'label': f.name,
          'tooltip': this.descriptions[f.key] ? this.descriptions[f.key] : f.description ? f.description : '',
          'type': f.fieldType.toUpperCase(),
          'model': 'CreateConnectionRequest',
          'value': null,
          'disabled': false,
          'fieldDescriptor': {
            'name': f.name,
            'complexType': null,
            'displayOrder': 2000,
            'allowableValuesKeys': [],
            'constraints': f.constraints,
            'formType': null
          },
          'options': null,
          'multiple': false,
          'required': f.constraints ? f.constraints.includes('required') : false,
          'showKey': false,
          'isCreate': false,
          'isUpdate': false
        };
        this.formList.push(customField);
      })
    },
    getInvalidFields() {
      let result = [];
      this.settingsData.constraints = [];

      for (let key in this.settingsData) {
        if (this.requiredFields.includes(key) && (this.settingsData[key] === null || this.settingsData[key] === '')) {
          result.push(key);
        }
      }

      return result;
    },
    updateSettingsData(val) {
      this.settingsData = val;
    },
    setInvalidFields(invalidFields) {
      invalidFields.forEach(invalidField => {
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;
        if (!invalidFieldElement.classList.contains('zq--form-row')) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      })
    },
    createSingleStep() {
      let invalidFields = this.getInvalidFields();
      if (!invalidFields.length) {
        this.createEntity();
      } else {
        this.setInvalidFields(invalidFields)
      }
    },
    createEntity() {
      let formData = {};
      if (Object.keys(this.settingsData).length) {
        delete this.settingsData.constraints;
        formData = {...this.settingsData};
      }
      formData.connectionType = 'Kafka';
      let customFields = {};
      let constraints = [];
      for(const key in formData) {
        if (this.customFields.findIndex(c => c.key === key) !== -1) {
          customFields[key] = formData[key];
          delete formData[key]
        }
        if (this.userConstraints.findIndex(c => c.key === key) !== -1) {
          if (formData[key]) constraints.push(key);
          delete formData[key]
        }
      }

      for (const key in customFields) {
        const type = this.formList.find(e => e.key === key).type;
        if (type && type === 'NUMBER') {
          customFields[key] = customFields[key] ? Number(customFields[key]) : null;
        }
      }

      formData.customFields = customFields;
      if (constraints.length) formData.constraints = constraints;

      this.ready = false;

      this.handleCreateConnection([JSON.parse(JSON.stringify(formData))])
        .then(data => {
          delay(() => {
            this.$router.push({
              name: 'PreviewConnection',
              params: {
                id: data[0].id,
              }
            })
          }, 2000)
        })
        .catch(() => {
          this.ready = true;
        });
    },
  },
};
</script>

<style lang="scss">
.create-page {
  &__header {
    background-color: var(--zq-main-bg);
  }
  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }
}
</style>
